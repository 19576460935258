<script setup lang="ts">
import { LoadingSpinner } from '@t16t/shared/components'
import { useAuthGuard } from '~/auth-module';

const { isAuthenticating } = useAuthGuard();
</script>

<template>
  <div class="max-w-screen-lg mx-auto min-h-screen font-inter">
    <div v-if="isAuthenticating" class="h-screen flex items-center justify-center">
      <LoadingSpinner class="w-20 h-20 text-primary-700" />
    </div>
    <div
      v-else
      class="flex flex-col lg:flex-row"
    >
      <div
        class="min-h-screen w-full overflow-y-auto bg-surface-100 border border-surface-200 shadow-inner lg:rounded-b-xl lg:rounded-t-xl lg:my-2"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
